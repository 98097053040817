import type { api } from '@meterup/proto';
import {
  Body,
  CopyBox,
  Section,
  SectionContent,
  SectionHeader,
  Small,
  space,
  Subheading,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  VStack,
} from '@meterup/atto';
import {
  bytesPerSecond,
  expectDefinedOrThrow,
  formatDataRateBytes,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
  Tooltip,
  UserRoleBadge,
} from '@meterup/common';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash-es';

import { fetchControllerJSON, fetchControllerState } from '../../../../api/controllers_api';
import { fetchCompanyUserJSON } from '../../../../api/users_api';
import { CopyToClipboardButtonMinimal } from '../../../../components/CopyToClipboardButton';
import { TimestampWithTimezone } from '../../../../components/timestamps';
import { useConfigEditor } from '../../../../context/ConfigEditorContext';
import { useCurrentTimezone } from '../../../../providers/CurrentTimezoneProvider';

export default function VPNClientDetailContent({
  controllerName,
  vpnClient,
  vpnServer,
}: {
  controllerName: string;
  vpnClient: api.VPNClient;
  vpnServer: api.VPNServer;
}) {
  const controller = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;
  const timezone = useCurrentTimezone();

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));

  const user = useQuery(
    ['company', controller.company_slug, 'users', vpnClient.user_sid],
    () => fetchCompanyUserJSON(controller.company_slug, vpnClient.user_sid),
    { suspense: true },
  ).data;

  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  // eslint-disable-next-line func-names
  const timestampToISO = function (unixTimestamp: number) {
    const date = new Date(unixTimestamp * 1000);
    return date.toISOString();
  };

  const peers = get(stateData?.state, ['meter.v1.wg-vpn-client-manager.peers']);
  const peersObj = get(peers, ['peers']);
  const logs = get(peersObj, [vpnClient.public_key.toString()]);
  const privateVLAN = useConfigEditor().draftModel.getVLANByName('private');

  expectDefinedOrThrow(user, new ResourceNotFoundError('User response not found'));
  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(
    privateVLAN,
    new ResourceNotFoundError(
      'Private VLAN config details not found, unable to format WireGuard config',
    ),
  );

  const dnsServers = privateVLAN.json.dhcp?.['dns-servers'] ?? [];

  let allowedIPs = vpnServer.default_client_allowed_ips.join(', ');
  if (allowedIPs.length === 0) allowedIPs = '10.0.0.0/8, 224.0.0.0/4';

  const wgConfig = `
[Interface]
PrivateKey = $YOUR_PRIVATE_KEY
Address = ${vpnClient.ip_address}/32
DNS = ${dnsServers.join(', ')}

[Peer]
PublicKey = ${vpnServer.public_key}
AllowedIPs = ${allowedIPs}
Endpoint = ${vpnServer.endpoint}:${vpnServer.port}
PersistentKeepalive = 30`.trim();

  return (
    <>
      <VStack align="center" spacing={space(10)}>
        <CopyBox
          aria-label="Copy VPN client name"
          relation="stacked"
          size="small"
          value={vpnClient.name}
        >
          <Subheading>{vpnClient.name}</Subheading>
        </CopyBox>
      </VStack>

      <Section relation="standalone">
        <SectionHeader icon="user" heading="User" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Name</SummaryListKey>
              <SummaryListValue>
                {user.first_name ?? '-'} {user.last_name}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Email</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy user email"
                  relation="stacked"
                  size="small"
                  value={user.email}
                >
                  <Body family="monospace">{user.email}</Body>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Role</SummaryListKey>
              <SummaryListValue>
                <UserRoleBadge value={user.company_role} />
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
      </Section>

      <Section relation="standalone">
        <SectionHeader icon="client" heading="Client" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Name</SummaryListKey>
              <SummaryListValue>{vpnClient.name}</SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>IP address</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN client IP address"
                  relation="stacked"
                  size="small"
                  value={vpnClient.ip_address?.toString() ?? ''}
                >
                  <Body family="monospace">{vpnClient.ip_address}</Body>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Public key</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy VPN client public key"
                  relation="stacked"
                  size="small"
                  value={vpnClient.public_key?.toString() ?? ''}
                >
                  <Body family="monospace">{vpnClient.public_key}</Body>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
            {isDefinedAndNotEmpty(vpnClient.created_at) && (
              <SummaryListRow>
                <SummaryListKey>Created at</SummaryListKey>
                <SummaryListValue>
                  <TimestampWithTimezone value={vpnClient.created_at} timezone={timezone} />
                </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(vpnClient.created_by) && (
              <SummaryListRow>
                <SummaryListKey>Created by</SummaryListKey>
                <SummaryListValue>
                  <Body family="monospace" wordBreak="break-all">
                    {vpnClient.created_by}
                  </Body>
                </SummaryListValue>
              </SummaryListRow>
            )}
          </SummaryList>
        </SectionContent>
      </Section>

      <Section relation="standalone">
        <SectionHeader
          icon="wrench"
          heading="WireGuard config"
          actions={<CopyToClipboardButtonMinimal text={wgConfig} />}
        />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListValue>
                <Small
                  as="pre"
                  display="block"
                  family="monospace"
                  whitespace="pre-wrap"
                  wordBreak="break-all"
                >
                  {wgConfig}
                </Small>
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
      </Section>

      {isDefinedAndNotEmpty(logs) && (
        <Section relation="standalone">
          <SectionHeader
            icon="insights"
            heading="Session"
            actions={<CopyToClipboardButtonMinimal text={wgConfig} />}
          />
          <SectionContent>
            <SummaryList>
              <SummaryListRow>
                <SummaryListKey>Rx bytes</SummaryListKey>
                <SummaryListValue>
                  <Body family="monospace" wordBreak="break-all">
                    {formatDataRateBytes(logs.rx_bytes, bytesPerSecond)}
                  </Body>
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Tx bytes</SummaryListKey>
                <SummaryListValue>
                  <SummaryListValue>
                    <Body family="monospace" wordBreak="break-all">
                      {formatDataRateBytes(logs.tx_bytes, bytesPerSecond)}
                    </Body>
                  </SummaryListValue>
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Last handshake time</SummaryListKey>
                <SummaryListValue>
                  {get(logs, ['last_handshake_time']) !== 0 && (
                    <TimestampWithTimezone
                      value={timestampToISO(logs.last_handshake_time)}
                      timezone={timezone}
                    />
                  )}
                  {get(logs, ['last_handshake_time']) === 0 && (
                    <Tooltip content="This peer has not completed a handshake since the VPN service was last restarted.">
                      Not since last restart
                    </Tooltip>
                  )}
                </SummaryListValue>
              </SummaryListRow>
              <SummaryListRow>
                <SummaryListKey>Observed at</SummaryListKey>
                <SummaryListValue>
                  <TimestampWithTimezone value={get(peers, ['observed_at'])} timezone={timezone} />
                </SummaryListValue>
              </SummaryListRow>
            </SummaryList>
          </SectionContent>
        </Section>
      )}
    </>
  );
}
