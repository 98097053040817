import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '@meterup/atto/src/utilities/types/polymorphicAsProp';
import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { HTMLAttributes, PropsWithChildren } from 'react';
import {
  activeThemeClassName,
  colors,
  css,
  darkThemeSelector,
  firefoxOnlyCSS,
  focusVisibleSelector,
  fonts,
  fontWeights,
  Icon,
  safariOnlyCSS,
  shadows,
  styled,
  useCanScrollX,
} from '@meterup/atto';
import classNames from 'classnames';
import React from 'react';
import { mergeRefs } from 'react-merge-refs';

const TableContainer = styled('div', {
  overflow: 'auto',
  WebkitOverflowScrolling: 'auto',
  // Establish a stacking context for the table.
  isolation: 'isolate',
});

const TableBase = styled('div', {
  width: '100%',
  position: 'relative',
  display: 'table',
  borderCollapse: 'collapse',
});

export const Table = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement>>
>(({ children, ...props }, ref) => {
  const [scrollRef, canScrollX] = useCanScrollX();
  return (
    <TableContainer {...props} ref={mergeRefs([ref, scrollRef])} data-can-scroll-x={canScrollX}>
      <TableBase>{children}</TableBase>
    </TableContainer>
  );
});

export const TableHead = styled('div', {
  display: 'table-header-group',
});

export const TableBody = styled('div', {
  display: 'table-row-group',
});

/**
 * TRICKY: The TableCellBase makes use of the :before and :after pseudo-elements.
 *
 *   :before => The row's focus ring. Each cell renders a part of the focus ring
 *   via a box-shadow, but the shadow is clipped horizontally by the cell's
 *   overflow: hidden property. This gives the illusion that the focus ring
 *   expands and contracts as the user scrolls horizontally.
 *
 *   :after => The first column's scroll shadow, visible when the table's
 *   content overflows.
 */
const TableCellBase = css({
  $$cellFocusRingInsetRight: 0,
  $$cellFocusRingInsetLeft: 0,

  display: 'table-cell',
  // Y-axis padding would be 10px, but the rows have a border, so we take 1px
  // off the top and bottom.
  padding: '0 $8',
  whiteSpace: 'nowrap',
  position: 'relative',
  zIndex: 0,
  overflow: 'hidden',
  color: '$$rowTextColor',
  boxShadow: '$$rowActiveFence',
  verticalAlign: 'middle',

  '&:before': {
    content: '',
    position: 'absolute',
    inset: '6px $$cellFocusRingInsetRight 6px $$cellFocusRingInsetLeft',
    pointerEvents: 'none',
  },

  '&:first-child': {
    paddingLeft: '$16',
    paddingRight: '$16',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'transparent',
    fontWeight: fontWeights.bold,
    color: '$$leadingColumnTextColor',
    left: 0,
    '&:before': {
      $$cellFocusRingInsetLeft: '6px',
      borderRadiusLeft: '$$rowFocusRingBorderRadius',
    },
    '&:after': {
      $$leadingColumnShadowSpace: '8px',
      content: '',
      position: 'absolute',
      inset: '0 $$leadingColumnShadowSpace 0 0',
      zIndex: -1,
      backgroundColor: '$$rowBackgroundColor',
      boxShadow: '$$rowActiveFence',
      pointerEvents: 'none',
      transition: 'box-shadow 150ms ease-out',
      ...safariOnlyCSS({
        inset: '-.5px $$leadingColumnShadowSpace -.5px 0',
      }),
    },
  },
  '&:last-child': {
    paddingRight: '$16',
    '&:before': {
      $$cellFocusRingInsetRight: '6px',
      borderRadiusRight: '$$rowFocusRingBorderRadius',
    },
  },

  '[data-can-scroll-x=true] &:first-child:after': {
    boxShadow: '$$leadingColumnScrollShadow, $$rowActiveFence',
  },
});

const TableRowBase = styled('div', {
  $$rowBackgroundColor: colors.white,
  $$rowIconColor: colors.gray400,
  $$rowTextColor: colors.gray600,
  $$leadingColumnTextColor: colors.gray700,
  $$rowFocusRingShadow: '0 0 0 1px #474d81, 0 0 0 3px #8790da',
  $$rowFocusRingBorderRadius: '6px',
  $$rowBorderColor: colors.strokeNeutralLight,
  $$rowActiveFence: '0 0 0 transparent',
  $$leadingColumnScrollShadow:
    '1px 0px 2px rgba(41, 42, 51, 0.04), 3px 0px 5px rgba(41, 42, 51, 0.08)',

  [darkThemeSelector]: {
    $$rowBackgroundColor: colors.bgApplicationDark,
    $$rowIconColor: colors.gray300,
    $$rowTextColor: colors.gray100,
    $$leadingColumnTextColor: colors.white,
    $$rowFocusRingShadow: '0 0 0 1px #c4c8ec, 0 0 0 4px #969bc0',
    $$rowBorderColor: colors.strokeNeutralDark,
    $$leadingColumnScrollShadow: '1px 0px 2px rgba(3, 4, 10, 0.6), 3px 0px 5px rgba(3, 4, 10, 0.4)',
  },

  position: 'relative',
  display: 'table-row',
  width: '100%',
  outline: 'none',

  borderTop: `1px solid $$rowBorderColor`,
  '&:last-child': {
    borderBottom: `1px solid $$rowBorderColor`,
  },

  // TRICKY: We want to set a background color on the row. It is typically
  // recommend to set a background color on each table cell. However, due to the
  // delicate z-index-ing with how we render a shadow and a focus ring on the
  // table cells, it's not possible to push the background to the table cells.
  // Chrome and Safari will render a row's background color just fine. However,
  // it's a known bug that Firefox will paint the row's background color over
  // the row's border. For Firefox, we use a pseudo-element to render the row
  // background color.
  //
  // You might think we could keep things simple and use the pseudo-element for
  // all three browsers. But no! Safari and Chrome do not have the background
  // color bug; however, Safari's behavior differs from Chrome's when a
  // table-row has position: relative. In Safari, the row does not create a
  // stacking context, so the pseudo-element is not positioned relative to the
  // row's content box. Instead, it is positioned relative to the table. Welcome
  // back to the browser wars.
  //
  // @see https://bugzilla.mozilla.org/show_bug.cgi?id=688556
  // @see https://developer.mozilla.org/en-US/docs/Web/CSS/position#values
  // @see https://en.wikipedia.org/wiki/Browser_wars
  backgroundColor: '$$rowBackgroundColor',
  ...firefoxOnlyCSS({
    backgroundColor: 'transparent',
    '&:before': {
      content: '',
      position: 'absolute',
      inset: 0,
      backgroundColor: '$$rowBackgroundColor',
      pointerEvents: 'none',
      // Set z-index so that the pseudo-element doesn't paint over the borders
      zIndex: -1,
    },
  }),

  [`& .${TableCellBase}:before`]: {
    transition: 'box-shadow 150ms ease-out',
    boxShadow: shadows.none,
  },
  [focusVisibleSelector]: {
    [`&:focus`]: {
      [`& .${TableCellBase}:before`]: {
        boxShadow: '$$rowFocusRingShadow',
      },
    },
  },

  [`&.${activeThemeClassName}`]: {
    $$rowBackgroundColor: colors.brand50,
    $$rowActiveFence: `inset 0 -1px 0 0 ${colors.strokeBrandLight}, inset 0 1px 0 0 ${colors.strokeBrandLight}`,
    $$rowTextColor: colors.brand700,
    $$leadingColumnTextColor: colors.brand800,
    $$rowIconColor: colors.brand700,
    $$rowFocusRingShadow: '0px 0px 0px 1px #dfe3f9, 0px 0px 0px 4px #a2a9e3',
    [darkThemeSelector]: {
      $$rowBackgroundColor: colors.brand900,
      $$rowActiveFence: `inset 0 -1px 0 0 ${colors.strokeBrandDark}, inset 0 1px 0 0 ${colors.strokeBrandDark}`,
      $$rowTextColor: colors.brand100,
      $$leadingColumnTextColor: colors.brand50,
      $$rowIconColor: colors.brand100,
      $$rowFocusRingShadow: '0px 0px 0px 1px #dfe3f9, 0px 0px 0px 4px #a2a9e3',
    },
  },
});

export interface TableRowProps {
  isSelected?: boolean;
  children?: React.ReactNode | undefined;
}

export const TableRow = React.forwardRef(
  <Tag extends React.ElementType>(
    { isSelected, ...props }: PolymorphicComponentProps<Tag, TableRowProps>,
    ref: PolymorphicRef<Tag>,
  ) => (
    <TableRowBase
      ref={ref}
      {...props}
      className={classNames({
        [activeThemeClassName]: isSelected,
      })}
    />
  ),
) as Polymorphic.ForwardRefComponent<React.ElementType, TableRowProps>;

export const TableHeadRow = styled(TableRowBase, {
  $$leadingColumnTextColor: colors.gray600,
  $$rowTextColor: colors.gray600,
  [darkThemeSelector]: {
    $$leadingColumnTextColor: colors.gray100,
    $$rowTextColor: colors.gray100,
  },
});

const TableHeadCellBase = styled('div', TableCellBase, {
  height: '27px',
  fontWeight: fontWeights.bold,
  fontFamily: fonts.sans,
  fontSize: '$12',
  lineHeight: '$16',
});

const TableHeadCellContent = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '$4',
});

const TableHeadCellSortIcon = styled(Icon, {
  width: '$10',
  height: '$10',
  variants: {
    isVisible: {
      true: {
        // TRICKY: Toggle visibility rather than display to always reserve
        // space in the layout for the icon.
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
  defaultVariants: {
    isVisible: false,
  },
});

export const TableHeadCell = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<HTMLAttributes<HTMLDivElement> & { sortDirection?: 'ascending' | 'descending' }>
>(({ children, sortDirection, ...props }, ref) => (
  <TableHeadCellBase {...props} ref={ref}>
    <TableHeadCellContent>
      {children}
      <TableHeadCellSortIcon
        isVisible={sortDirection !== undefined}
        icon={sortDirection === 'ascending' ? 'chevron-up' : 'chevron-down'}
      />
    </TableHeadCellContent>
  </TableHeadCellBase>
));

export const TableDataCell = styled('div', TableCellBase, {
  height: '35px',
  fontFamily: fonts.sans,
  fontVariant: 'tabular-nums',
  fontWeight: fontWeights.regular,
  fontSize: '$12',
  lineHeight: '$16',
});
