import type { Node } from '@meterup/command';
import {
  Body,
  ComboBox,
  ComboBoxItem,
  CopyBox,
  darkThemeSelector,
  Icon,
  PaneHeader,
  Tab,
} from '@meterup/atto';
import { useCommand, useRegisterCommands } from '@meterup/command';
import {
  checkDefinedOrThrow,
  DetailPageLayouts,
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
} from '@meterup/common';
import { formatLifecycleStatus } from '@meterup/common/src/helpers/lifecycleStatus';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

import { fetchCompanyControllersJSON, fetchCompanyJSON } from '../api/company_api';
import { fetchControllerJSON } from '../api/controllers_api';
import { Nav } from '../components/Nav';
import { paths } from '../constants';
import { useIsPathActiveFn } from '../hooks/useIsPathActiveFn';
import { colors, fontWeights, styled } from '../stitches';
import { makeLink } from '../utils/makeLink';

const StyledIcon = styled(Icon, { color: colors.gray500, width: '$8', height: '$8' });

const CompanyLink = styled(ReactRouterLink, Body, {
  color: colors.blue600,
  fontWeight: fontWeights.bold,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ControllerSelectContainer = styled('div', {
  maxWidth: '320px',
});

const ControllerEphemera = styled('div', {
  display: 'flex',
  alignItems: 'center',
  hStack: '$8',
  padding: '$12 $20',
  strokeAll: colors.strokeApplicationLight,
  [darkThemeSelector]: {
    outlineColor: colors.strokeApplicationDark,
  },

  '@notDesktop': {
    minHeight: '$52',
  },

  '@desktop': {
    minHeight: '$44',
  },
});

const ControllerAddress = styled(Body, {
  color: colors.gray600,
});

function getControllerLabel(controller: api.ControllerResponse): string {
  return [
    controller.label ? `[${controller.label}]` : null,
    controller.lifecycle_status !== api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN
      ? `[${formatLifecycleStatus(controller.lifecycle_status)}]`
      : null,
    controller.address,
    '-',
    controller.name,
  ]
    .filter(Boolean)
    .join(' ');
}

export function ControllerPageHeader() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const isPathActive = useIsPathActiveFn();

  const controllerData = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(controllerData);

  const companyData = useQuery(
    ['companies', controllerName],
    () => fetchCompanyJSON(controllerData.company_slug),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(controllerData.company_slug),
    },
  ).data;

  const companyControllers =
    useQuery(
      ['company-controllers'],
      () => fetchCompanyControllersJSON(controllerData.company_slug),
      {
        suspense: true,
        enabled: isDefinedAndNotEmpty(controllerData.company_slug),
      },
    ).data ?? [];

  const navigate = useNavigate();

  const { state } = useCommand();

  const commands = useMemo(() => {
    const nodes = [
      state.nodeFactory.action({
        id: 'details',
        label: 'View Details',
        display: 'View Details',
        group: controllerName,
        synonyms: 'go to',
        icon: 'information',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerDetails, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'access-points',
        label: 'View Access Points',
        display: 'View Access Points',
        group: controllerName,
        synonyms: 'go to',
        icon: 'access-point',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerDevicesList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'switches',
        label: 'View Switches',
        display: 'View Switches',
        group: controllerName,
        synonyms: 'go to',
        icon: 'switch',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerSwitchesList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'clients',
        label: 'View Clients',
        display: 'View Clients',
        group: controllerName,
        synonyms: 'go to',
        icon: 'client',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerClientsList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'ISPs',
        label: 'View ISPs',
        display: 'View ISPs',
        group: controllerName,
        synonyms: 'go to',
        icon: 'wired',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerServicePlans, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'logs',
        label: 'View Logs',
        display: 'View Logs',
        synonyms: 'config go to',
        group: controllerName,
        icon: 'log',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerLogs, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'stats',
        label: 'View Stats',
        display: 'View Stats',
        synonyms: 'config go to',
        group: controllerName,
        icon: 'insights',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerStats, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'incidents',
        label: 'View Incidents',
        display: 'View Incidents',
        group: controllerName,
        synonyms: 'go to',
        icon: 'warning',
        onSelect() {
          navigate(makeLink(paths.pages.ControllerIncidentsList, { controllerName }));
        },
      }),
      state.nodeFactory.action({
        id: 'vpn',
        label: 'View VPN',
        display: 'View VPN',
        group: controllerName,
        synonyms: 'go to',
        icon: 'secure',
        onSelect() {
          navigate(makeLink(paths.pages.VPNListPage, { controllerName }));
        },
      }),
    ].filter(Boolean) as Node[];
    return nodes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerName]);

  useRegisterCommands(commands);

  return (
    <DetailPageLayouts.HeaderContainer>
      {(isDefined(companyData) || isDefinedAndNotEmpty(controllerData.address)) && (
        <ControllerEphemera>
          {isDefined(companyData) && (
            <CompanyLink
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, {
                  companyName: controllerData.company_slug,
                }),
              })}
            >
              {companyData.name}
            </CompanyLink>
          )}
          {(isDefined(companyData) || isDefinedAndNotEmpty(controllerData.address)) && (
            <StyledIcon icon="chevron-right" />
          )}
          {isDefinedAndNotEmpty(controllerData.address) &&
            isDefinedAndNotEmpty(controllerData.company_slug) &&
            (companyControllers.length > 1 ? (
              <ControllerSelectContainer>
                <ComboBox
                  aria-label="Select controller"
                  value={controllerData.name}
                  size="small"
                  onValueChange={(value: string) =>
                    navigate(makeLink(paths.pages.ControllerDetails, { controllerName: value }))
                  }
                >
                  {companyControllers.map((controller) => (
                    <ComboBoxItem key={controller.name} textValue={getControllerLabel(controller)}>
                      {getControllerLabel(controller)}
                    </ComboBoxItem>
                  ))}
                </ComboBox>
              </ControllerSelectContainer>
            ) : (
              <ControllerAddress>
                {controllerData.address}{' '}
                {controllerData.lifecycle_status !== api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN &&
                  `(${formatLifecycleStatus(controllerData.lifecycle_status)})`}
              </ControllerAddress>
            ))}
        </ControllerEphemera>
      )}
      <PaneHeader
        icon="security-appliance"
        heading={
          <CopyBox
            aria-label="Copy controller name"
            relation="stacked"
            size="small"
            value={controllerName}
          >
            {controllerName}
          </CopyBox>
        }
        tabs={
          <>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDetails, { controllerName })}
              selected={isPathActive(paths.pages.ControllerDetails, { end: true })}
              icon="information"
            >
              Details
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDevicesList, { controllerName })}
              selected={isPathActive(paths.pages.ControllerDevicesList)}
              icon="access-point"
            >
              Access points
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerSwitchesList, { controllerName })}
              selected={isPathActive(paths.pages.ControllerSwitchesList)}
              icon="switch"
            >
              Switches
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerClientsList, { controllerName })}
              selected={isPathActive(paths.pages.ControllerClientsList)}
              icon="client"
            >
              Clients
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerServicePlans, { controllerName })}
              selected={isPathActive(paths.pages.ControllerServicePlans)}
              icon="wired"
            >
              ISPs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerLogs, { controllerName })}
              selected={isPathActive(paths.pages.ControllerLogs)}
              icon="log"
            >
              Logs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerStats, { controllerName })}
              selected={isPathActive(paths.pages.ControllerStats)}
              icon="insights"
            >
              Stats
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerConfig, { controllerName })}
              selected={isPathActive(paths.pages.ControllerConfig)}
              icon="wrench"
            >
              Config
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerState, { controllerName })}
              selected={isPathActive(paths.pages.ControllerState)}
              icon="pulse"
            >
              State
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerIncidentsList, { controllerName })}
              selected={isPathActive(paths.pages.ControllerIncidentsList)}
              icon="warning"
            >
              Incidents
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VPNListPage, { controllerName })}
              selected={isPathActive(paths.pages.VPNListPage)}
              icon="secure"
            >
              VPN
            </Tab>
          </>
        }
      />
    </DetailPageLayouts.HeaderContainer>
  );
}
