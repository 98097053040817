import { isGuest, isMeterManufacturer } from '@meterup/common';
import ouiData from 'oui-data';
import { match } from 'ts-pattern';

import type { NetworkClientsQuery } from '../gql/graphql';
import { isTimestampKnown } from '../components/timestamps';

export type NetworkClient = NetworkClientsQuery['networkClients'][number];

export const isWired = (c: NetworkClient) => Number(c.signal) === 0;
export const isWireless = (c: NetworkClient) => Number(c.signal) !== 0;

export const isMeterSwitch = (c: NetworkClient) => c.clientName?.startsWith('meter-ms') ?? false;

export const isMeterAccessPoint = (c: NetworkClient) =>
  (c.clientName?.startsWith('meter-ap') || c.clientName?.startsWith('meter-mw')) ?? false;

export const lookupMACAddressOUI = (macAddress: string): string | undefined => {
  const stripped = macAddress
    .replace(/[^0-9a-f]/gi, '')
    .substring(0, 6)
    .toUpperCase();

  const macOUIData: string | undefined =
    stripped in ouiData ? ouiData[stripped as keyof typeof ouiData] : undefined;

  return macOUIData?.substring(0, macOUIData.indexOf('\n'));
};

export const isMeterHardware = (c: NetworkClient) =>
  isMeterManufacturer(lookupMACAddressOUI(c.macAddress) ?? '') ||
  isMeterSwitch(c) ||
  isMeterAccessPoint(c);

export const isLastSeenKnown = (c: NetworkClient): c is NetworkClient & { lastSeen: string } =>
  isTimestampKnown(c.lastSeen);

export enum FilterStrategy {
  All = 'all',
  Wired = 'wired',
  Wireless = 'wireless',
  Guest = 'guest',
  Meter = 'meter',
  MeterSwitch = 'meter-switch',
  MeterAccessPoint = 'meter-access-point',
}

export const getPredicateFromStrategy = (strategy: FilterStrategy) =>
  match(strategy)
    .with(FilterStrategy.All, () => () => true)
    .with(FilterStrategy.Wired, () => isWired)
    .with(FilterStrategy.Wireless, () => isWireless)
    .with(FilterStrategy.Guest, () => isGuest)
    .with(FilterStrategy.Meter, () => isMeterHardware)
    .with(FilterStrategy.MeterSwitch, () => isMeterSwitch)
    .with(FilterStrategy.MeterAccessPoint, () => isMeterAccessPoint)
    .exhaustive();
