import {
  Badge,
  Body,
  Button,
  CopyBox,
  HStack,
  IconTooltip,
  Section,
  SectionContent,
  SectionHeader,
  SectionTarget,
  Small,
  space,
  Subheading,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  VStack,
} from '@meterup/atto';
import {
  checkDefinedOrThrow,
  DeviceStatusIcon,
  isDefinedAndNotEmpty,
  isGuest,
  isOffline,
  isOnline,
  NeutralBadge,
  OnlineOfflineClientCountBadge,
  WirelessBandDetails,
} from '@meterup/common';
import { api } from '@meterup/proto';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { fetchClientsGql } from '../../api/clients_api';
import { fetchLatestMemoryStatus, fetchLatestUptimeMetric } from '../../api/metrics';
import { paths } from '../../constants';
import { getGrafanaURL } from '../../utils/grafana';
import { makeDrawerLink, makeLink } from '../../utils/makeLink';
import { kbToHuman, secToHuman } from '../../utils/units';
import { NosUpgradeWidget } from '../NosUpgradeWidget';
import { DistanceToNowTimestamp } from '../timestamps';
import { WidgetSuspenseAndErrorBoundary } from '../WidgetSuspenseAndErrorBoundary';

function getEnabledRadio(
  accessPoint: api.AccessPointDeviceResponse | null | undefined,
  band: api.RadioBand,
): api.AccessPointRadioConfig | null {
  if (accessPoint) {
    const radio = accessPoint.radios.find((r) => r.band === band && r.enabled) ?? null;
    if (radio) {
      const radioConfig = {
        band: radio.band,
        channel: radio.channel,
        enabled: radio.enabled,
        tx_power_dBm: radio.tx_power_dBm,
        channel_width_MHz: radio.channel_width_MHz,
      } as api.AccessPointRadioConfig;
      return radioConfig;
    }
  }
  return null;
}

export default function DeviceDetailContent({
  controllerName,
  device,
  networkUUID,
}: {
  device: api.AccessPointDeviceResponse;
  controllerName: string;
  networkUUID: string;
}) {
  const accessPoint = checkDefinedOrThrow(device);

  const lastAPClientConnection = useQuery(['lease', device.mac_address], () =>
    fetchClientsGql(networkUUID, device.mac_address).then(
      (connectionHistory) => connectionHistory[0] ?? null,
    ),
  ).data;
  const clients =
    useQuery(['clients', controllerName, accessPoint.serial_number], () =>
      fetchClientsGql(networkUUID, undefined, accessPoint.serial_number),
    ).data ?? [];

  const onlineClients = clients.filter(isOnline);
  const userClients = onlineClients.filter((c) => !isGuest(c));
  const guestClients = onlineClients.filter(isGuest);
  const recentClients = clients.filter(isOffline);

  const uptime = useQuery(
    ['accesspoint', accessPoint.name, 'latest_uptime'],
    () => fetchLatestUptimeMetric(accessPoint.name),
    { suspense: true, refetchInterval: 60 * 1000 },
  ).data;

  const memoryStats = useQuery(
    ['accesspoint', accessPoint.name, 'latest_memory'],
    () => fetchLatestMemoryStatus(accessPoint.name),
    { suspense: true, refetchInterval: 60 * 1000 },
  ).data;
  const memAvailPercent = (
    ((memoryStats?.available || 0) / (memoryStats?.total || 1)) *
    100
  ).toFixed();

  const ipAddress = device.ip_address || lastAPClientConnection?.ip;

  return (
    <>
      <VStack align="center" spacing={space(10)}>
        <HStack>
          <DeviceStatusIcon value={accessPoint.connected_status} />
        </HStack>
        <CopyBox
          aria-label="Copy access point name"
          relation="stacked"
          size="small"
          value={accessPoint.name}
        >
          <Subheading>{accessPoint.name}</Subheading>
        </CopyBox>
      </VStack>

      <Section relation="standalone">
        <SectionHeader
          icon="information"
          heading="Details"
          actions={
            <Button
              as={Link}
              to={makeDrawerLink(paths.drawers.DeviceEditLocation, {
                controllerName,
                id: accessPoint.name,
              })}
              variant="secondary"
              size="small"
            >
              Edit
            </Button>
          }
        />
        <SummaryList>
          <SummaryListRow>
            <SummaryListKey>Serial number</SummaryListKey>
            <SummaryListValue>
              <CopyBox
                aria-label="Copy serial number"
                relation="stacked"
                size="small"
                value={accessPoint.serial_number}
              >
                <Body family="monospace">{accessPoint.serial_number}</Body>
              </CopyBox>
            </SummaryListValue>
          </SummaryListRow>
          {isDefinedAndNotEmpty(ipAddress) && (
            <SummaryListRow>
              <SummaryListKey>IP address</SummaryListKey>
              <SummaryListValue>
                <HStack align="center" spacing={space(6)}>
                  <CopyBox
                    aria-label="Copy IP address to clipboard"
                    relation="stacked"
                    size="small"
                    value={ipAddress}
                  >
                    <Body family="monospace">{ipAddress}</Body>
                  </CopyBox>
                  {lastAPClientConnection?.lastSeen && ipAddress === lastAPClientConnection?.ip && (
                    <IconTooltip
                      toggle
                      contents={
                        <Small>
                          Last updated{' '}
                          <DistanceToNowTimestamp value={lastAPClientConnection.lastSeen} />
                        </Small>
                      }
                      icon="information"
                      size={12}
                    />
                  )}
                </HStack>
              </SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(accessPoint.mac_address) && (
            <SummaryListRow>
              <SummaryListKey>MAC address</SummaryListKey>
              <SummaryListValue>
                <CopyBox
                  aria-label="Copy MAC address"
                  relation="stacked"
                  size="small"
                  value={accessPoint.mac_address}
                >
                  <Body family="monospace">{accessPoint.mac_address}</Body>
                </CopyBox>
              </SummaryListValue>
            </SummaryListRow>
          )}
          <SummaryListRow>
            <SummaryListKey>Version</SummaryListKey>
            <SummaryListValue>
              <Badge size="small">{accessPoint.firmware_version}</Badge>
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Build</SummaryListKey>
            <SummaryListValue>
              {accessPoint.build_name}
              {accessPoint.configured_build_name !== '' &&
                accessPoint.build_name !== accessPoint.configured_build_name && (
                  <b>(does not match configured {accessPoint.configured_build_name})</b>
                )}
            </SummaryListValue>
          </SummaryListRow>
          <SummaryListRow>
            <SummaryListKey>Location</SummaryListKey>
            <SummaryListValue>{accessPoint.location}</SummaryListValue>
          </SummaryListRow>
          {isDefinedAndNotEmpty(uptime) && (
            <SummaryListRow>
              <SummaryListKey>Uptime</SummaryListKey>
              <SummaryListValue>{secToHuman(uptime)}</SummaryListValue>
            </SummaryListRow>
          )}
          {isDefinedAndNotEmpty(memoryStats) && (
            <SummaryListRow>
              <SummaryListKey>Memory Available</SummaryListKey>
              <SummaryListValue>
                {kbToHuman(memoryStats?.available || 0)} of {kbToHuman(memoryStats?.total || 0)} (
                {memAvailPercent}% free)
              </SummaryListValue>
            </SummaryListRow>
          )}
        </SummaryList>
      </Section>
      <Section relation="standalone">
        <WidgetSuspenseAndErrorBoundary title="Firmware Updates">
          <NosUpgradeWidget serial={accessPoint.serial_number} editable={false} />
        </WidgetSuspenseAndErrorBoundary>
      </Section>

      <Section relation="standalone">
        <SectionHeader icon="client" heading="Clients" />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Online</SummaryListKey>
              <SummaryListValue>
                <OnlineOfflineClientCountBadge icon="wifi" value={onlineClients.length} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Users</SummaryListKey>
              <SummaryListValue>
                <OnlineOfflineClientCountBadge icon="wifi" value={userClients.length} />
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Guests</SummaryListKey>
              <SummaryListValue>
                <OnlineOfflineClientCountBadge icon="wifi" value={guestClients.length} />
              </SummaryListValue>
            </SummaryListRow>

            <SummaryListRow>
              <SummaryListKey>Recent</SummaryListKey>
              <SummaryListValue>
                <NeutralBadge
                  arrangement="leading-icon"
                  icon={recentClients.length > 0 ? 'client' : undefined}
                >
                  {recentClients.length}
                </NeutralBadge>
              </SummaryListValue>
            </SummaryListRow>
          </SummaryList>
        </SectionContent>
        <SectionTarget
          tabIndex={0}
          as={Link}
          to={makeLink(paths.pages.ControllerDeviceClients, {
            controllerName,
            deviceName: accessPoint.name,
          })}
        >
          View clients
        </SectionTarget>
      </Section>

      <Button
        variant="secondary"
        width="100%"
        size="large"
        as="a"
        href={getGrafanaURL(controllerName, accessPoint.name)}
        target="_blank"
        icon="insights"
        arrangement="leading-icon"
      >
        Open Grafana
      </Button>

      <WirelessBandDetails
        title="5G band"
        radio={getEnabledRadio(accessPoint, api.RadioBand.RB_5G)}
      />
      <WirelessBandDetails
        title="2.4G band"
        radio={getEnabledRadio(accessPoint, api.RadioBand.RB_2G)}
      />
    </>
  );
}
