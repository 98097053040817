import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  Alert,
  Body,
  Button,
  copyTextToClipboard,
  HStack,
  Legends,
  Link,
  PaneContent,
  Port,
  Section,
  SectionContent,
  SectionHeader,
  space,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
  Text,
  Tooltip,
  VStack,
} from '@meterup/atto';
import { useCommand, useRegisterCommands } from '@meterup/command';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  OnlineOfflineStatusBadge,
  ResourceNotFoundError,
} from '@meterup/common';
import UnifiedFileUploader from '@meterup/common/src/components/UnifiedFileUploader';
import { formatLifecycleStatus } from '@meterup/common/src/helpers/lifecycleStatus';
import { useGraphQL, useGraphQLMutation } from '@meterup/graphql';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  fetchControllerJSON,
  fetchControllerState,
  fetchIncidents,
} from '../../../api/controllers_api';
import {
  fetchLatestLinkStatus,
  fetchLatestMemoryStatus,
  fetchLatestUptimeMetric,
} from '../../../api/metrics';
import { AccessPointsWidget } from '../../../components/AccessPointsWidget';
import { BootinfoWidget } from '../../../components/BootinfoWidget';
import { ClientsWidget } from '../../../components/ClientsWidget';
import { FirmwareUpdatesWidget } from '../../../components/FirmwareUpdatesWidget';
import { Nav } from '../../../components/Nav';
import { NosUpgradeWidget } from '../../../components/NosUpgradeWidget';
import { PageSection } from '../../../components/Page';
import { ServiceSetsWidgetCOS } from '../../../components/ServiceSetsWidgetCOS';
import { CurrentLocalTime, StringToDateTime } from '../../../components/timestamps';
import { WidgetSuspenseAndErrorBoundary } from '../../../components/WidgetSuspenseAndErrorBoundary';
import { paths } from '../../../constants';
import { graphql } from '../../../gql';
import { AllowedFolder } from '../../../gql/graphql';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import { styled } from '../../../stitches';
import { formatControllerVersionFromState } from '../../../utils/controllerVersion';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { NosFeature, useNosFeatureEnabled } from '../../../utils/nosfeature';
import { bytesToHuman, secToHuman } from '../../../utils/units';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName',
});

const PageColumns = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$16',
  '@sm': {
    gridTemplateColumns: '2fr 3fr',
  },
});

const PageColumn = styled('div', {
  vStack: '$16',
  alignItems: 'stretch',
});

const PageFirstColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '1',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

const PageSecondColumn = styled(PageColumn, {
  '@sm': {
    gridColumn: '2',
  },
  vStack: '$16',
  alignItems: 'stretch',
});

function getCOSVersion(parsedCOSVersion: string, mbootVersion: string) {
  if (isDefinedAndNotEmpty(parsedCOSVersion)) {
    return parsedCOSVersion;
  }
  if (isDefinedAndNotEmpty(mbootVersion)) {
    return `Legacy ${mbootVersion}`;
  }
  return 'Unknown';
}

const getDeviceHeartbeatDoc = graphql(`
  query deviceHeartbeatForDevice($serialNumber: String!) {
    deviceHeartbeatForDevice(serialNumber: $serialNumber) {
      UUID
      token
    }
  }
`);

const createDeviceHeartbeatDoc = graphql(`
  mutation createDeviceHeartbeat($serialNumber: String!) {
    createDeviceHeartbeat(input: { serialNumber: $serialNumber }) {
      UUID
    }
  }
`);

const virtualNetworkInfoQuery = graphql(`
  query ControllerDetailsNetwork($uuid: UUID!) {
    network(UUID: $uuid) {
      UUID
      topologyImageURL
    }
  }
`);

const updateTopologyImageMutation = graphql(`
  mutation UpdateControllerTopologyImage($networkUUID: UUID!, $s3Key: String) {
    updateNetwork(UUID: $networkUUID, input: { topologyImageS3Key: $s3Key }) {
      UUID
    }
  }
`);

export const cellularUsageQuery = graphql(`
  query DeviceLastDayCellularUsage($serialNumber: String!) {
    deviceLastDayCellularUsage(serialNumber: $serialNumber) {
      uploadBytes
      downloadBytes
    }
  }
`);

function ControllerMetadataWidget({ controllerName }: { controllerName: string }) {
  const { data: networkInfoJSON } = useQuery(
    ['controller', controllerName, 'network-info'],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  );

  const virtualNetworkInfo = useGraphQL(
    virtualNetworkInfoQuery,
    { uuid: networkInfoJSON?.network_uuid! },
    { enabled: !!networkInfoJSON?.network_uuid, useErrorBoundary: false },
  );

  const updateTopologyImage = useGraphQLMutation(updateTopologyImageMutation);

  expectDefinedOrThrow(
    networkInfoJSON,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  const controllerState = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true },
  ).data;

  const linkStatus = useQuery(
    ['controller', controllerName, 'link_status'],
    async () => {
      const links = await fetchLatestLinkStatus(controllerName);
      links.sort((a, b) => a.wan.localeCompare(b.wan));
      return links;
    },
    { suspense: true },
  ).data;

  const uptime = useQuery(
    ['controller', controllerName, 'latest_uptime'],
    () => fetchLatestUptimeMetric(controllerName),
    { suspense: true, refetchInterval: 60 * 1000 },
  ).data;

  const memoryStats = useQuery(
    ['controller', controllerName, 'latest_memory'],
    () => fetchLatestMemoryStatus(controllerName),
    { suspense: true, refetchInterval: 60 * 1000 },
  ).data;
  const memAvailPercent = (
    ((memoryStats?.available || 0) / (memoryStats?.total || 1)) *
    100
  ).toFixed();

  const deviceHeartbeat = useGraphQL(
    getDeviceHeartbeatDoc,
    { serialNumber: controllerName },
    {
      suspense: false,
      useErrorBoundary: false,
    },
  );

  const cellularUsage = useGraphQL(
    cellularUsageQuery,
    { serialNumber: controllerName },
    { useErrorBoundary: false },
  ).data;

  const createDeviceHeartbeat = useGraphQLMutation(createDeviceHeartbeatDoc, {});

  const handleCreateDeviceHeartbeat = () => {
    createDeviceHeartbeat.mutate(
      { serialNumber: controllerName },
      {
        onSuccess: async () => {
          await deviceHeartbeat.refetch();
        },
      },
    );
  };

  const [showTopologyImageDropzone, setShowTopologyImageDropzone] = useState(false);
  const [, parsedCOSVersion] = formatControllerVersionFromState(controllerState);

  const navigate = useNavigate();
  const { state } = useCommand();

  const metadataEditLink = makeDrawerLink(paths.drawers.EditControllerMetadata, { controllerName });

  useRegisterCommands([
    state.nodeFactory.action({
      id: 'edit-metadata',
      label: 'Edit metadata',
      display: 'Edit metadata',
      icon: 'pencil',
      synonyms: 'change name address update',
      onSelect() {
        navigate(metadataEditLink);
      },
    }),
    state.nodeFactory.action({
      id: 'copy-name',
      label: 'Copy name to clipboard',
      display: 'Copy name to clipboard',
      icon: 'copy',
      shortcut: 'Control+Shift+C',
      onSelect() {
        copyTextToClipboard(controllerName);
      },
    }),
    state.nodeFactory.action({
      id: 'copy-company-slug',
      label: 'Copy company slug to clipboard',
      display: 'Copy company slug to clipboard',
      icon: 'copy',
      shortcut: 'Control+Shift+S',
      onSelect() {
        copyTextToClipboard(networkInfoJSON.company_slug);
      },
    }),
  ]);

  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  return (
    <>
      <Button
        variant="secondary"
        width="100%"
        size="large"
        as="a"
        target="_blank"
        href={
          isCOS2Enabled
            ? `${import.meta.env.DASHBOARD_URL}/org/${
                networkInfoJSON.company_slug
              }/network/${networkInfoJSON.network_uuid}`
            : `${import.meta.env.DASHBOARD_URL}/org/${
                networkInfoJSON.company_slug
              }/controller/${controllerName}`
        }
        disabled={!networkInfoJSON.company_slug}
      >
        Open Dashboard
      </Button>
      <Section relation="standalone">
        <SectionHeader
          heading="Metadata"
          actions={
            <Button as={RouterLink} to={metadataEditLink} variant="secondary" size="small">
              Edit
            </Button>
          }
        />
        <SectionContent>
          <SummaryList>
            <SummaryListRow>
              <SummaryListKey>Company</SummaryListKey>
              <SummaryListValue>
                {isDefinedAndNotEmpty(networkInfoJSON.company_slug) ? (
                  <Link
                    as={RouterLink}
                    to={Nav.makeTo({
                      root: makeLink(paths.pages.CompaniesList, {}),
                      drawer: makeLink(paths.drawers.CompanySummary, {
                        companyName: networkInfoJSON.company_slug,
                      }),
                    })}
                  >
                    {networkInfoJSON.company_slug}
                  </Link>
                ) : (
                  '-'
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Status</SummaryListKey>
              <SummaryListValue>
                <OnlineOfflineStatusBadge
                  value={networkInfoJSON.status}
                  arrangement="leading-label"
                >
                  {networkInfoJSON.status.charAt(0).toUpperCase() + networkInfoJSON.status.slice(1)}
                </OnlineOfflineStatusBadge>
              </SummaryListValue>
            </SummaryListRow>
            {isDefinedAndNotEmpty(linkStatus) && linkStatus.length > 0 && (
              <SummaryListRow>
                <SummaryListKey>Link status</SummaryListKey>
                <SummaryListValue>
                  <VStack spacing={space(8)}>
                    <HStack spacing={space(6)}>
                      {linkStatus.map((status, index) => (
                        <Tooltip
                          key={status.wan}
                          contents={`Reported at ${status.time.toLocaleTimeString()}`}
                          asChild={false}
                        >
                          <VStack align="center">
                            <Port
                              port="ethernet"
                              number={index}
                              status={status.status === 'online' ? 'connected' : 'disconnected'}
                              uplink
                            />
                            <Text size="12">{status.wan?.toUpperCase()}</Text>
                          </VStack>
                        </Tooltip>
                      ))}
                    </HStack>
                    <Legends
                      legends={[
                        { variant: 'neutral', label: 'Disconnected' },
                        { variant: 'positive', label: 'Connected' },
                      ]}
                    />
                  </VStack>
                </SummaryListValue>
              </SummaryListRow>
            )}
            <SummaryListRow>
              <SummaryListKey>Lifecycle status</SummaryListKey>
              <SummaryListValue>
                {formatLifecycleStatus(networkInfoJSON.lifecycle_status)}{' '}
              </SummaryListValue>
            </SummaryListRow>
            {isDefinedAndNotEmpty(networkInfoJSON.label) && (
              <SummaryListRow>
                <SummaryListKey>Label</SummaryListKey>
                <SummaryListValue>{networkInfoJSON.label} </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(networkInfoJSON.timezone) && (
              <SummaryListRow>
                <SummaryListKey>Timezone</SummaryListKey>
                <SummaryListValue>{networkInfoJSON.timezone} </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(networkInfoJSON.firmware_version) ? (
              <SummaryListRow>
                <SummaryListKey>NOS version</SummaryListKey>
                <SummaryListValue>{networkInfoJSON.firmware_version}</SummaryListValue>
              </SummaryListRow>
            ) : (
              <SummaryListRow>
                <SummaryListKey>COS version</SummaryListKey>
                <SummaryListValue>
                  {getCOSVersion(parsedCOSVersion, networkInfoJSON.mboot_version)}
                </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(networkInfoJSON.build_name) && (
              <SummaryListRow>
                <SummaryListKey>Build</SummaryListKey>
                <SummaryListValue>
                  {networkInfoJSON.build_name}
                  {networkInfoJSON.configured_build_name !== '' &&
                    networkInfoJSON.build_name !== networkInfoJSON.configured_build_name && (
                      <b>(does not match configured {networkInfoJSON.configured_build_name})</b>
                    )}
                </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(uptime) && (
              <SummaryListRow>
                <SummaryListKey>Uptime</SummaryListKey>
                <SummaryListValue>{secToHuman(uptime)}</SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(memoryStats) && (
              <SummaryListRow>
                <SummaryListKey>Memory available</SummaryListKey>
                <SummaryListValue>
                  {bytesToHuman(memoryStats?.available)} of {bytesToHuman(memoryStats?.total)} (
                  {memAvailPercent}% free)
                </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(networkInfoJSON.noc_metadata?.patch_panel_diagram_url) && (
              <SummaryListRow>
                <SummaryListKey>Patch panel diagram</SummaryListKey>
                <SummaryListValue>
                  <Button
                    as="a"
                    href={networkInfoJSON.noc_metadata?.patch_panel_diagram_url}
                    target="_blank"
                    size="small"
                    variant="secondary"
                  >
                    Open diagram
                  </Button>
                </SummaryListValue>
              </SummaryListRow>
            )}
            {isDefinedAndNotEmpty(virtualNetworkInfo.data?.network) && (
              <>
                <SummaryListRow>
                  <SummaryListKey>Topology diagram</SummaryListKey>
                  <SummaryListValue>
                    <HStack spacing={space(4)}>
                      {isDefinedAndNotEmpty(virtualNetworkInfo.data.network.topologyImageURL) && (
                        <Button
                          as="a"
                          href={virtualNetworkInfo.data.network.topologyImageURL}
                          target="_blank"
                          size="small"
                          variant="secondary"
                        >
                          Open diagram
                        </Button>
                      )}
                      <Button
                        size="small"
                        variant={
                          virtualNetworkInfo.data.network.topologyImageURL
                            ? 'secondary'
                            : 'secondary'
                        }
                        onClick={() => {
                          setShowTopologyImageDropzone((prev) => !prev);
                        }}
                        loading={updateTopologyImage.isLoading}
                      >
                        {showTopologyImageDropzone ? 'Cancel' : 'Upload'}
                      </Button>
                    </HStack>
                  </SummaryListValue>
                </SummaryListRow>
                {showTopologyImageDropzone && (
                  <UnifiedFileUploader
                    folder={AllowedFolder.NetworkTopologyPhotos}
                    allowedTypes={{
                      images: true,
                    }}
                    maxFiles={1}
                    onFileUploaded={({ s3Key }) => {
                      updateTopologyImage.mutate(
                        { networkUUID: networkInfoJSON.network_uuid, s3Key },
                        {
                          onSuccess: () => {
                            virtualNetworkInfo.refetch();
                            setShowTopologyImageDropzone(false);
                          },
                        },
                      );
                    }}
                    compact
                  />
                )}
              </>
            )}
            <SummaryListRow>
              <SummaryListKey>DeadMansSnitch</SummaryListKey>
              <SummaryListValue>
                {deviceHeartbeat.isSuccess && deviceHeartbeat.data.deviceHeartbeatForDevice ? (
                  <Button
                    as="a"
                    href={`https://deadmanssnitch.com/snitches/${deviceHeartbeat.data.deviceHeartbeatForDevice.token}`}
                    target="_blank"
                    size="small"
                    variant="secondary"
                  >
                    View
                  </Button>
                ) : (
                  <Button size="small" variant="secondary" onClick={handleCreateDeviceHeartbeat}>
                    Create
                  </Button>
                )}
              </SummaryListValue>
            </SummaryListRow>
            <SummaryListRow>
              <SummaryListKey>Cellular Usage (24h)</SummaryListKey>
              <SummaryListValue>
                Download:{' '}
                {cellularUsage?.deviceLastDayCellularUsage?.downloadBytes ||
                cellularUsage?.deviceLastDayCellularUsage?.downloadBytes === 0
                  ? bytesToHuman(cellularUsage.deviceLastDayCellularUsage?.downloadBytes)
                  : 'Unknown'}{' '}
                Upload:{' '}
                {cellularUsage?.deviceLastDayCellularUsage?.uploadBytes ||
                cellularUsage?.deviceLastDayCellularUsage?.uploadBytes === 0
                  ? bytesToHuman(cellularUsage.deviceLastDayCellularUsage?.uploadBytes)
                  : 'Unknown'}{' '}
              </SummaryListValue>
            </SummaryListRow>
            {networkInfoJSON?.noc_metadata?.notes && (
              <SummaryListRow>
                <SummaryListKey>Notes</SummaryListKey>
                <SummaryListValue>
                  <Body style={{ whiteSpace: 'pre-line' }}>
                    {networkInfoJSON.noc_metadata.notes}
                  </Body>
                </SummaryListValue>
              </SummaryListRow>
            )}
          </SummaryList>
        </SectionContent>
      </Section>
    </>
  );
}

export default function ControllerDetails() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  expectDefinedOrThrow(controllerName);

  const controller = useQuery(
    ['controller', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  const incidents =
    useQuery(['controller', controllerName, 'incidents'], () => fetchIncidents(controllerName), {
      suspense: true,
    }).data ?? [];

  const now = CurrentLocalTime();
  const currentIncident = incidents.find((i) => {
    const start = StringToDateTime(i.start_time ?? '');
    const end = StringToDateTime(i.end_time ?? '');
    return start <= now && now <= end;
  });

  const AlertContainer = styled('div', { marginBottom: '$16' });

  const currentIncidentAlert = currentIncident ? (
    <AlertContainer>
      <Alert
        icon="attention"
        variant="negative"
        heading="Ongoing incident"
        copy="There is an active incident on this controller."
        relation="stacked"
        trailingButtons={
          <Button
            as={RouterLink}
            to={Nav.makeTo({
              root: makeLink(paths.pages.ControllerIncidentsList, { controllerName }),
              drawer: makeLink(paths.drawers.IncidentDetail, {
                controllerName,
                id: currentIncident.sid,
              }),
            })}
          >
            View incident
          </Button>
        }
      />
    </AlertContainer>
  ) : null;

  useDocumentTitle('', controllerName);

  const isCOS2Enabled = useNosFeatureEnabled(controllerName, NosFeature.COS2);

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  return (
    <PaneContent>
      {isCOS2Enabled && (
        <Alert
          variant="negative"
          copy="This network uses COS Config 2.0. Use Dashboard to configure this network."
          heading="Attention"
          relation="stacked"
          icon="warning"
          shoulderButtons={
            networkInfo ? (
              <Button
                variant="secondary"
                as="a"
                target="_blank"
                href={`${import.meta.env.DASHBOARD_URL}/org/${
                  networkInfo.company_slug
                }/network/${networkInfo.network_uuid}`}
              >
                View dashboard
              </Button>
            ) : undefined
          }
        />
      )}
      <PageSection style={{ padding: 16 }}>
        {currentIncidentAlert}
        <PageColumns>
          <PageFirstColumn>
            <WidgetSuspenseAndErrorBoundary title="Metadata">
              <ControllerMetadataWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Service sets">
              <ServiceSetsWidgetCOS controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
          </PageFirstColumn>
          <PageSecondColumn>
            <WidgetSuspenseAndErrorBoundary title="Access points">
              <AccessPointsWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Clients">
              <ClientsWidget
                controllerName={controllerName}
                networkUUID={controller!.network_uuid}
              />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Firmware updates">
              <FirmwareUpdatesWidget controllerName={controllerName} />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Firmware updates">
              <NosUpgradeWidget serial={controllerName} editable />
            </WidgetSuspenseAndErrorBoundary>
            <WidgetSuspenseAndErrorBoundary title="Boot history">
              <BootinfoWidget serial={controllerName} single={false} />
            </WidgetSuspenseAndErrorBoundary>
          </PageSecondColumn>
        </PageColumns>
      </PageSection>
    </PaneContent>
  );
}
